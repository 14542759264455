import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: []
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
  }
});

export const categoriesActions = categoriesSlice.actions;
export default categoriesSlice.reducer;