import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  save: false,
  error: null,
  data: [],
  allData: []
};

export const pricingSlice = createSlice({
  name: "pricing",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
      state.error = null;
    },
    setSave: (state, { payload }) => {
      state.save = payload;
    },
    setError: (state, { payload }) => {
      state.error = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setAllData: (state, { payload }) => {
      state.allData = payload;
    }
  }
});

export const pricingActions = pricingSlice.actions;
export default pricingSlice.reducer;
