import React, { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { PaymentMethodRepository } from "../../../repositories/paymenMethod";
import "../../../assets/scss/custom/pages/_paymentform.scss";
import { useTranslation } from "react-i18next";
import PaymentMethodFormV2 from "../PaymentMethodFormV2";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import "./index.css";

import p1 from "../../../assets/images/partners/bread-house.png";
import p2 from "../../../assets/images/partners/mosaico.png";
import p3 from "../../../assets/images/partners/los-antojitos.png";
import p4 from "../../../assets/images/partners/mistcity.png";
import p5 from "../../../assets/images/partners/deluxe.png";
import p6 from "../../../assets/images/partners/vaping.png";
import p7 from "../../../assets/images/partners/veteplus.png";
import p8 from "../../../assets/images/partners/avenda-uno.png";
import p9 from "../../../assets/images/partners/elgir.png";
import p10 from "../../../assets/images/partners/eureka.png";
import p11 from "../../../assets/images/partners/beauty-life.png";
import p12 from "../../../assets/images/partners/green-studio.png";
import moment from "moment";
import { getMonthName } from "../../../core/utils/dates";
import StoreSubscriptionsRepository from "../../../repositories/store_subscriptions";

import { useIsDesktop } from "../../../helpers/useIsDesktop";
import { useScreenHeight } from "../../../helpers/useScreenHeight";
import { useScreenWidth } from "../../../helpers/useScreenWidth";
import { useSelector } from "react-redux";
import { rowItems } from "../../../data/free-data";
import { RowItem } from "../Register/components/RowItems";
import { toLower } from "lodash";
import { PresentationView } from "../Register/components/PresentationView";
import { AUTH_USER_KEY } from "../../../routes/constants";
import { useCheckSubscription } from "../../../app/hook/useCheckSubscription";

const partners = [p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12];

const PaymentForm = (props) => {
  const { planId } = useParams();
  useCheckSubscription(planId)
  const { history } = props;
  const { t } = useTranslation();
  const isDesktop = useIsDesktop();
  const screenHeight = useScreenHeight();
  const screenWidth = useScreenWidth();
  const [dataPricing, setDataPricing] = useState(0);
  const noPayment = true;
  const [processStep, setProcessStep] = useState(0);
  const currentUser = JSON.parse(localStorage.getItem(AUTH_USER_KEY));

  const setNextStep = () => {
    setProcessStep(processStep + 1);
  };

  const currentId = currentUser.store.country_id.id

  const handlerPayment = async (payload) => {
    const repository = new PaymentMethodRepository();
    if (currentId === 1) {
      const response = await repository.firstPayment({
        subscription_product_id: Number(planId),
        method: { ...payload },
        noPaymentSet: false,
      });
      if (response.status === 201) {
        Swal.fire({
          title: "",
          text: t("The payment method has been successfully updated"),
          icon: "success",
          confirmButton: true,
          confirmButtonColor: "#1186FA",
        });
        if (isDesktop) {
          history.push("/profile-details");
        } else {
          history.push("/finish-up");
        }
      } else {
        Swal.fire({
          title: "Error!",
          text:
            t(response.response.data.message) ||
            "Ocurrió un error, por favor verifique que su método de pago sea válido.",
          icon: "error",
          confirmButton: true,
        });
      }
    }

    if (currentId === 2) {
      const response = await repository.secondPayment({
        subscriptionId: Number(planId),
        method: { ...payload },
        noPaymentSet: false,
      });
      if (response.status === 201) {
        Swal.fire({
          title: "",
          text: t("The payment method has been successfully updated"),
          icon: "success",
          confirmButton: true,
        });
        if (isDesktop) {
          history.push("/profile-details");
        } else {
          history.push("/finish-up");
        }
      } else {
        Swal.fire({
          title: "Error!",
          text:
            t(response.response.data.message) ||
            "Ocurrió un error, por favor verifique que su método de pago sea válido.",
          icon: "error",
          confirmButton: true,
        });
      }
    }
  };

  const handlerNoPayment = async () => {
    const repository = new PaymentMethodRepository();
    if (currentId === 1) {
      const response = await repository.firstPayment({
        subscription_product_id: Number(planId),
        noPaymentSet: noPayment,
      });
      if (response.status === 201) {
        if (isDesktop) {
          history.push("/profile-details");
        } else {
          history.push("/finish-up");
        }
      }
    }

    if (currentId === 2) {
      const response = await repository.secondPayment({
        subscriptionId: Number(planId),
        noPaymentSet: noPayment,
      });
      if (response.status === 201) {
        if (isDesktop) {
          history.push("/profile-details");
        } else {
          history.push("/finish-up");
        }
      }
    }
  };

  const styles = isDesktop
    ? {
        maxWidth: screenWidth,
        overflowX: "hidden",
      }
    : {
        overflowX: "hidden",
      };

  const containerStyle = {
    ...styles,
    height: screenHeight,
    overflowX: "hidden",
  };

  const nextMonth = moment(new Date()).add(1, "month");
  const month = getMonthName(nextMonth.month());

  useEffect(() => {
    const fetchData = async () => {
      try {
        const repository = new StoreSubscriptionsRepository();
        const response = await repository.getSelectedSubscription(planId);
        setDataPricing(response);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [planId]);

  const basePrice = dataPricing.base_price;

  return (
    <>
      {isDesktop && (
        <div fluid className="register" style={containerStyle}>
          <Row>
            <Col lg={6}>
              <Row className="justify-content-center">
                <Col lg="10" className="mt-4">
                  <PaymentMethodFormV2
                    handlerManagePayment={handlerPayment}
                    handlerNoPayment={handlerNoPayment}
                    noPayment={noPayment}
                    paymentAmount={"0"}
                    newMembership={true}
                    pricing={dataPricing}
                    basePrice={basePrice}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="p-3 justify-content-center">
                <Col sm="12" lg="11">
                  <div className="mt-5">
                    {rowItems.map((item, i) => (
                      <RowItem
                        title={item.title}
                        desc={item.desc}
                        img={item.img}
                        key={i}
                      />
                    ))}
                  </div>
                  <Row>
                    {partners.map((partner) => (
                      <Col
                        lg={2}
                        style={{
                          display: "grid",
                          alignItems: "center",
                        }}
                        className={
                          "d-flex align-items-center justify-content-center"
                        }
                      >
                        <figure>
                          <img src={partner} alt="Partner" />
                        </figure>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
      {!isDesktop && (
        <div>
          {processStep === 0 ? (
            <PresentationView setNextStep={() => setNextStep()} />
          ) : (
            <div
              style={
                isDesktop
                  ? {}
                  : {
                      minHeight: screenHeight,
                      maxWidth: screenWidth,
                      backgroundColor: "#FFF",
                    }
              }
            >
              <Row>
                <Col xs={12} sm={12}>
                  <Row className="justify-content-center">
                    <Col xs="12" sm={12}>
                      <PaymentMethodFormV2
                        handlerManagePayment={handlerPayment}
                        handlerNoPayment={handlerNoPayment}
                        noPayment={noPayment}
                        paymentAmount={"0"}
                        newMembership={true}
                        pricing={dataPricing}
                        basePrice={basePrice}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PaymentForm;
