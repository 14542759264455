import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useIsDesktop } from "../../helpers/useIsDesktop";

export const useCheckSubscription = (id) => {
  const history = useHistory();
  const store = useSelector((state) => state.store.store);
  const { pathname } = useLocation();
  const isDesktop = useIsDesktop();


  useEffect(() => {
    if (pathname === "/dashboard" && store?.subcription === null) {
      if (store?.configuration_completed === false) {
        history.push("/pricing");
      }
      history.push("/pricing");
    }

    if (pathname === "/dashboard" && store?.configuration_completed === false) {
      if (isDesktop) {
        history.push("/profile-details");
      } else {
        history.push("/finish-up");
      }
    }

  }, [history, store, isDesktop, pathname, id]);
};
