import { createSlice } from "@reduxjs/toolkit";
import { data } from "autoprefixer";

const initialState = {
  isLoading: false,
  data: [],
  saving : false,
  isChanged: false
};

export const mySubscriptionSlice = createSlice({
  name: "my-subscription",
  initialState,
  reducers: {
    load: (state, action) => {
      state.isLoading = true;
      state.data = []
    },
    loaded: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    update: (state, { payload }) => {
      state.data = payload
    },
    saving: (state, action) => {
      state.saving = action.payload
    },
    setChange: (state, action) => {
      state.isChanged = action.payload;
    }
  }
});

export const mySubscriptionActions = mySubscriptionSlice.actions;
export default mySubscriptionSlice.reducer;