import { axiosApi } from "../helpers/api_helper";

export class StoreSubscriptionsRepository {
  endpoint = "/store_subscriptions";
  endpoint2 = "/store_payment_methods";

  async getSubsciption() {
    const response = await axiosApi.get(`${this.endpoint}`);
    return response.data;
  }

  async getRedeemedPrizes(params = {}) {
    const response = await axiosApi.get(
      `${this.endpoint}/redeemed-prizes-list`,
      { params }
    );
    return response.data;
  }

  async getMembers(params = {}) {
    const response = await axiosApi.get(`${this.endpoint}/member-list`, {
      params
    });
    return response.data;
  }

  async getMemberInfo(id) {
    const response = await axiosApi.get(
      `${this.endpoint}/member-all-info/${id}`
    );
    return response.data;
  }

  async getProductSubscription(segmentation, free) {
    const response = await axiosApi.get(
      `${this.endpoint2}/subscription-plan?segmentation=${segmentation}&includeIsFree=${free}`
    );
    return response.data;
  }

  async getAllProductSubscription() {
    const response = await axiosApi.get(
      `${this.endpoint2}/subscription-plan`
    );
    return response.data;
  }

  async getMySubscription() {
    const response = await axiosApi.get(
      `${this.endpoint2}/my-subscription-plan`
    );
    return response.data;
  }

  async updateMySubscription(id, data) {
    return await axiosApi.put(
      `${this.endpoint2}/change-my-plan/${id}`, data
    );
 
  }

  async getSelectedSubscription(id) {
    const response = await axiosApi.get(
      `${this.endpoint}/subscription-product/${id}`
    );
    return response.data;
  }
}

//http://localhost:3000/store_subscriptions/subscription-product/1

export default StoreSubscriptionsRepository;
