import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: []
};

export const configurationSlice = createSlice({
  name: "configuration",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
    update: (state, { payload }) => {
        state.data = payload;
    }
  }
});

export const configurationActions = configurationSlice.actions;
export default configurationSlice.reducer;