import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoading: false,
  data: []
};

export const permissionSlice = createSlice({
  name: "permission",
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setData: (state, { payload }) => {
      state.data = payload;
    },
  }
});

export const permissionActions = permissionSlice.actions;
export default permissionSlice.reducer;